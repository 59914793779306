/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata,
} from '@ngx-grpc/common';
import { GRPC_CLIENT_FACTORY, GrpcHandler, takeMessages, throwStatusErrors } from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './self.pb';
import * as registration000 from '../../common/services/configurations.pb';
import * as profiles001 from '../../common/auth/profiles.pb';
import { GRPC_SELF_SERVICE_CLIENT_SETTINGS } from './self.pbconf';
/**
 * Service client implementation for self.SelfService
 */
@Injectable({ providedIn: 'any' })
export class SelfServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /self.SelfService/GetProfile
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<profiles001.ProfileResponse>>
     */
    getProfile: (
      requestData: profiles001.ProfileRequest,
      requestMetadata = new GrpcMetadata(),
    ): Observable<GrpcEvent<profiles001.ProfileResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/self.SelfService/GetProfile',
        requestData,
        requestMetadata,
        requestClass: profiles001.ProfileRequest,
        responseClass: profiles001.ProfileResponse,
      });
    },
    /**
     * Unary call: /self.SelfService/ChangeLocale
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<profiles001.LocaleSettings>>
     */
    changeLocale: (
      requestData: profiles001.LocaleSettings,
      requestMetadata = new GrpcMetadata(),
    ): Observable<GrpcEvent<profiles001.LocaleSettings>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/self.SelfService/ChangeLocale',
        requestData,
        requestMetadata,
        requestClass: profiles001.LocaleSettings,
        responseClass: profiles001.LocaleSettings,
      });
    },
    /**
     * Unary call: /self.SelfService/CheckEmail
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CheckEmailResponse>>
     */
    checkEmail: (
      requestData: thisProto.SendRequest,
      requestMetadata = new GrpcMetadata(),
    ): Observable<GrpcEvent<thisProto.CheckEmailResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/self.SelfService/CheckEmail',
        requestData,
        requestMetadata,
        requestClass: thisProto.SendRequest,
        responseClass: thisProto.CheckEmailResponse,
      });
    },
    /**
     * Unary call: /self.SelfService/ResendConfirmation
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.SendResponse>>
     */
    resendConfirmation: (
      requestData: thisProto.SendRequest,
      requestMetadata = new GrpcMetadata(),
    ): Observable<GrpcEvent<thisProto.SendResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/self.SelfService/ResendConfirmation',
        requestData,
        requestMetadata,
        requestClass: thisProto.SendRequest,
        responseClass: thisProto.SendResponse,
      });
    },
    /**
     * Unary call: /self.SelfService/UpdateProfile
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateResponse>>
     */
    updateProfile: (
      requestData: thisProto.UpdateRequest,
      requestMetadata = new GrpcMetadata(),
    ): Observable<GrpcEvent<thisProto.UpdateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/self.SelfService/UpdateProfile',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateRequest,
        responseClass: thisProto.UpdateResponse,
      });
    },
    /**
     * Unary call: /self.SelfService/SwitchProfile
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ProfileSwitchResponse>>
     */
    switchProfile: (
      requestData: thisProto.ProfileSwitchRequest,
      requestMetadata = new GrpcMetadata(),
    ): Observable<GrpcEvent<thisProto.ProfileSwitchResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/self.SelfService/SwitchProfile',
        requestData,
        requestMetadata,
        requestClass: thisProto.ProfileSwitchRequest,
        responseClass: thisProto.ProfileSwitchResponse,
      });
    },
  };

  constructor(
    @Optional() @Inject(GRPC_SELF_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler,
  ) {
    this.client = clientFactory.createClient('self.SelfService', settings);
  }

  /**
   * Unary call @/self.SelfService/GetProfile
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<profiles001.ProfileResponse>
   */
  getProfile(
    requestData: profiles001.ProfileRequest,
    requestMetadata = new GrpcMetadata(),
  ): Observable<profiles001.ProfileResponse> {
    return this.$raw
      .getProfile(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/self.SelfService/ChangeLocale
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<profiles001.LocaleSettings>
   */
  changeLocale(
    requestData: profiles001.LocaleSettings,
    requestMetadata = new GrpcMetadata(),
  ): Observable<profiles001.LocaleSettings> {
    return this.$raw
      .changeLocale(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/self.SelfService/CheckEmail
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CheckEmailResponse>
   */
  checkEmail(
    requestData: thisProto.SendRequest,
    requestMetadata = new GrpcMetadata(),
  ): Observable<thisProto.CheckEmailResponse> {
    return this.$raw
      .checkEmail(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/self.SelfService/ResendConfirmation
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.SendResponse>
   */
  resendConfirmation(
    requestData: thisProto.SendRequest,
    requestMetadata = new GrpcMetadata(),
  ): Observable<thisProto.SendResponse> {
    return this.$raw
      .resendConfirmation(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/self.SelfService/UpdateProfile
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateResponse>
   */
  updateProfile(
    requestData: thisProto.UpdateRequest,
    requestMetadata = new GrpcMetadata(),
  ): Observable<thisProto.UpdateResponse> {
    return this.$raw
      .updateProfile(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/self.SelfService/SwitchProfile
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ProfileSwitchResponse>
   */
  switchProfile(
    requestData: thisProto.ProfileSwitchRequest,
    requestMetadata = new GrpcMetadata(),
  ): Observable<thisProto.ProfileSwitchResponse> {
    return this.$raw
      .switchProfile(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
