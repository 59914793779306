import { AccessRule } from '../abstract-rule-access';
import { OperationCode } from '@camino-solutions/share/proto/common/services';

export class SubscriptionsListLicencesRule extends AccessRule {
  static #instance: SubscriptionsListLicencesRule;

  static get instance(): SubscriptionsListLicencesRule {
    if (SubscriptionsListLicencesRule.#instance === undefined) {
      SubscriptionsListLicencesRule.#instance = new SubscriptionsListLicencesRule();
    }
    return SubscriptionsListLicencesRule.#instance;
  }

  constructor() {
    super(OperationCode.SubscriptionsListLicences);
    SubscriptionsListLicencesRule.#instance = this;
  }
}
