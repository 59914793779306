import { AccessRule } from '../abstract-rule-access';
import { OperationCode } from '@camino-solutions/share/proto/common/services';

export class CoreInviteCompanyRule extends AccessRule {
  static #instance: CoreInviteCompanyRule;

  static get instance(): CoreInviteCompanyRule {
    if (CoreInviteCompanyRule.#instance === undefined) {
      CoreInviteCompanyRule.#instance = new CoreInviteCompanyRule();
    }
    return CoreInviteCompanyRule.#instance;
  }

  constructor() {
    super(OperationCode.CoreInviteCompany);
    CoreInviteCompanyRule.#instance = this;
  }
}
