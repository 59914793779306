/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { GrpcMessage, RecursivePartial, ToProtobufJSONOptions } from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as registration000 from '../../common/services/configurations.pb';
import * as profiles001 from '../../common/auth/profiles.pb';
/**
 * Message implementation for self.SendRequest
 */
export class SendRequest implements GrpcMessage {
  static id = 'self.SendRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SendRequest();
    SendRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SendRequest) {
    _instance.clientId = _instance.clientId || '';
    _instance.emailAddress = _instance.emailAddress || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: SendRequest, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.clientId = _reader.readString();
          break;
        case 2:
          _instance.emailAddress = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    SendRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: SendRequest, _writer: BinaryWriter) {
    if (_instance.clientId) {
      _writer.writeString(1, _instance.clientId);
    }
    if (_instance.emailAddress) {
      _writer.writeString(2, _instance.emailAddress);
    }
  }

  private _clientId: string;
  private _emailAddress: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SendRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<SendRequest.AsObject>) {
    _value = _value || {};
    this.clientId = _value.clientId;
    this.emailAddress = _value.emailAddress;
    SendRequest.refineValues(this);
  }
  get clientId(): string {
    return this._clientId;
  }
  set clientId(value: string) {
    this._clientId = value;
  }
  get emailAddress(): string {
    return this._emailAddress;
  }
  set emailAddress(value: string) {
    this._emailAddress = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SendRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SendRequest.AsObject {
    return {
      clientId: this.clientId,
      emailAddress: this.emailAddress,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions,
  ): SendRequest.AsProtobufJSON {
    return {
      clientId: this.clientId,
      emailAddress: this.emailAddress,
    };
  }
}
export module SendRequest {
  /**
   * Standard JavaScript object representation for SendRequest
   */
  export interface AsObject {
    clientId: string;
    emailAddress: string;
  }

  /**
   * Protobuf JSON representation for SendRequest
   */
  export interface AsProtobufJSON {
    clientId: string;
    emailAddress: string;
  }
}

/**
 * Message implementation for self.CheckEmailResponse
 */
export class CheckEmailResponse implements GrpcMessage {
  static id = 'self.CheckEmailResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CheckEmailResponse();
    CheckEmailResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CheckEmailResponse) {
    _instance.valid = _instance.valid || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: CheckEmailResponse, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.valid = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    CheckEmailResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: CheckEmailResponse, _writer: BinaryWriter) {
    if (_instance.valid) {
      _writer.writeBool(1, _instance.valid);
    }
  }

  private _valid: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CheckEmailResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CheckEmailResponse.AsObject>) {
    _value = _value || {};
    this.valid = _value.valid;
    CheckEmailResponse.refineValues(this);
  }
  get valid(): boolean {
    return this._valid;
  }
  set valid(value: boolean) {
    this._valid = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CheckEmailResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CheckEmailResponse.AsObject {
    return {
      valid: this.valid,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions,
  ): CheckEmailResponse.AsProtobufJSON {
    return {
      valid: this.valid,
    };
  }
}
export module CheckEmailResponse {
  /**
   * Standard JavaScript object representation for CheckEmailResponse
   */
  export interface AsObject {
    valid: boolean;
  }

  /**
   * Protobuf JSON representation for CheckEmailResponse
   */
  export interface AsProtobufJSON {
    valid: boolean;
  }
}

/**
 * Message implementation for self.SendResponse
 */
export class SendResponse implements GrpcMessage {
  static id = 'self.SendResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SendResponse();
    SendResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SendResponse) {
    _instance.success = _instance.success || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: SendResponse, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.success = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    SendResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: SendResponse, _writer: BinaryWriter) {
    if (_instance.success) {
      _writer.writeBool(1, _instance.success);
    }
  }

  private _success: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SendResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<SendResponse.AsObject>) {
    _value = _value || {};
    this.success = _value.success;
    SendResponse.refineValues(this);
  }
  get success(): boolean {
    return this._success;
  }
  set success(value: boolean) {
    this._success = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SendResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SendResponse.AsObject {
    return {
      success: this.success,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions,
  ): SendResponse.AsProtobufJSON {
    return {
      success: this.success,
    };
  }
}
export module SendResponse {
  /**
   * Standard JavaScript object representation for SendResponse
   */
  export interface AsObject {
    success: boolean;
  }

  /**
   * Protobuf JSON representation for SendResponse
   */
  export interface AsProtobufJSON {
    success: boolean;
  }
}

/**
 * Message implementation for self.UpdateRequest
 */
export class UpdateRequest implements GrpcMessage {
  static id = 'self.UpdateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateRequest();
    UpdateRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateRequest) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: UpdateRequest, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.refreshProfilePicture = _reader.readBool();
          break;
        case 3:
          _instance.phoneNumber = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: UpdateRequest, _writer: BinaryWriter) {
    if (_instance.name || _instance.name === '') {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.refreshProfilePicture || _instance.refreshProfilePicture === false) {
      _writer.writeBool(2, _instance.refreshProfilePicture);
    }
    if (_instance.phoneNumber || _instance.phoneNumber === '') {
      _writer.writeString(3, _instance.phoneNumber);
    }
  }

  private _name: string;
  private _refreshProfilePicture: boolean;
  private _phoneNumber: string;

  private _data: UpdateRequest.DataCase = UpdateRequest.DataCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateRequest.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.refreshProfilePicture = _value.refreshProfilePicture;
    this.phoneNumber = _value.phoneNumber;
    UpdateRequest.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    if (value !== undefined && value !== null) {
      this._refreshProfilePicture = this._phoneNumber = undefined;
      this._data = UpdateRequest.DataCase.name;
    }
    this._name = value;
  }
  get refreshProfilePicture(): boolean {
    return this._refreshProfilePicture;
  }
  set refreshProfilePicture(value: boolean) {
    if (value !== undefined && value !== null) {
      this._name = this._phoneNumber = undefined;
      this._data = UpdateRequest.DataCase.refreshProfilePicture;
    }
    this._refreshProfilePicture = value;
  }
  get phoneNumber(): string {
    return this._phoneNumber;
  }
  set phoneNumber(value: string) {
    if (value !== undefined && value !== null) {
      this._name = this._refreshProfilePicture = undefined;
      this._data = UpdateRequest.DataCase.phoneNumber;
    }
    this._phoneNumber = value;
  }
  get data() {
    return this._data;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateRequest.AsObject {
    return {
      name: this.name,
      refreshProfilePicture: this.refreshProfilePicture,
      phoneNumber: this.phoneNumber,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions,
  ): UpdateRequest.AsProtobufJSON {
    return {
      name: this.name === null || this.name === undefined ? null : this.name,
      refreshProfilePicture: this.refreshProfilePicture,
      phoneNumber:
        this.phoneNumber === null || this.phoneNumber === undefined ? null : this.phoneNumber,
    };
  }
}
export module UpdateRequest {
  /**
   * Standard JavaScript object representation for UpdateRequest
   */
  export interface AsObject {
    name: string;
    refreshProfilePicture: boolean;
    phoneNumber: string;
  }

  /**
   * Protobuf JSON representation for UpdateRequest
   */
  export interface AsProtobufJSON {
    name: string | null;
    refreshProfilePicture: boolean;
    phoneNumber: string | null;
  }
  export enum DataCase {
    none = 0,
    name = 1,
    refreshProfilePicture = 2,
    phoneNumber = 3,
  }
}

/**
 * Message implementation for self.UpdateResponse
 */
export class UpdateResponse implements GrpcMessage {
  static id = 'self.UpdateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateResponse();
    UpdateResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateResponse) {
    _instance.success = _instance.success || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: UpdateResponse, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.success = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: UpdateResponse, _writer: BinaryWriter) {
    if (_instance.success) {
      _writer.writeBool(1, _instance.success);
    }
  }

  private _success: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateResponse.AsObject>) {
    _value = _value || {};
    this.success = _value.success;
    UpdateResponse.refineValues(this);
  }
  get success(): boolean {
    return this._success;
  }
  set success(value: boolean) {
    this._success = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateResponse.AsObject {
    return {
      success: this.success,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions,
  ): UpdateResponse.AsProtobufJSON {
    return {
      success: this.success,
    };
  }
}
export module UpdateResponse {
  /**
   * Standard JavaScript object representation for UpdateResponse
   */
  export interface AsObject {
    success: boolean;
  }

  /**
   * Protobuf JSON representation for UpdateResponse
   */
  export interface AsProtobufJSON {
    success: boolean;
  }
}

/**
 * Message implementation for self.ProfileSwitchRequest
 */
export class ProfileSwitchRequest implements GrpcMessage {
  static id = 'self.ProfileSwitchRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ProfileSwitchRequest();
    ProfileSwitchRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ProfileSwitchRequest) {
    _instance.profile = _instance.profile || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: ProfileSwitchRequest, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.profile = new profiles001.ProfileInfo();
          _reader.readMessage(
            _instance.profile,
            profiles001.ProfileInfo.deserializeBinaryFromReader,
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ProfileSwitchRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: ProfileSwitchRequest, _writer: BinaryWriter) {
    if (_instance.profile) {
      _writer.writeMessage(
        1,
        _instance.profile as any,
        profiles001.ProfileInfo.serializeBinaryToWriter,
      );
    }
  }

  private _profile?: profiles001.ProfileInfo;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ProfileSwitchRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ProfileSwitchRequest.AsObject>) {
    _value = _value || {};
    this.profile = _value.profile ? new profiles001.ProfileInfo(_value.profile) : undefined;
    ProfileSwitchRequest.refineValues(this);
  }
  get profile(): profiles001.ProfileInfo | undefined {
    return this._profile;
  }
  set profile(value: profiles001.ProfileInfo | undefined) {
    this._profile = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ProfileSwitchRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ProfileSwitchRequest.AsObject {
    return {
      profile: this.profile ? this.profile.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions,
  ): ProfileSwitchRequest.AsProtobufJSON {
    return {
      profile: this.profile ? this.profile.toProtobufJSON(options) : null,
    };
  }
}
export module ProfileSwitchRequest {
  /**
   * Standard JavaScript object representation for ProfileSwitchRequest
   */
  export interface AsObject {
    profile?: profiles001.ProfileInfo.AsObject;
  }

  /**
   * Protobuf JSON representation for ProfileSwitchRequest
   */
  export interface AsProtobufJSON {
    profile: profiles001.ProfileInfo.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for self.ProfileSwitchResponse
 */
export class ProfileSwitchResponse implements GrpcMessage {
  static id = 'self.ProfileSwitchResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ProfileSwitchResponse();
    ProfileSwitchResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ProfileSwitchResponse) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: ProfileSwitchResponse, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.success = _reader.readBool();
          break;
        case 2:
          _instance.redirectUri = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ProfileSwitchResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: ProfileSwitchResponse, _writer: BinaryWriter) {
    if (_instance.success || _instance.success === false) {
      _writer.writeBool(1, _instance.success);
    }
    if (_instance.redirectUri || _instance.redirectUri === '') {
      _writer.writeString(2, _instance.redirectUri);
    }
  }

  private _success: boolean;
  private _redirectUri: string;

  private _response: ProfileSwitchResponse.ResponseCase = ProfileSwitchResponse.ResponseCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ProfileSwitchResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ProfileSwitchResponse.AsObject>) {
    _value = _value || {};
    this.success = _value.success;
    this.redirectUri = _value.redirectUri;
    ProfileSwitchResponse.refineValues(this);
  }
  get success(): boolean {
    return this._success;
  }
  set success(value: boolean) {
    if (value !== undefined && value !== null) {
      this._redirectUri = undefined;
      this._response = ProfileSwitchResponse.ResponseCase.success;
    }
    this._success = value;
  }
  get redirectUri(): string {
    return this._redirectUri;
  }
  set redirectUri(value: string) {
    if (value !== undefined && value !== null) {
      this._success = undefined;
      this._response = ProfileSwitchResponse.ResponseCase.redirectUri;
    }
    this._redirectUri = value;
  }
  get response() {
    return this._response;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ProfileSwitchResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ProfileSwitchResponse.AsObject {
    return {
      success: this.success,
      redirectUri: this.redirectUri,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions,
  ): ProfileSwitchResponse.AsProtobufJSON {
    return {
      success: this.success,
      redirectUri:
        this.redirectUri === null || this.redirectUri === undefined ? null : this.redirectUri,
    };
  }
}
export module ProfileSwitchResponse {
  /**
   * Standard JavaScript object representation for ProfileSwitchResponse
   */
  export interface AsObject {
    success: boolean;
    redirectUri: string;
  }

  /**
   * Protobuf JSON representation for ProfileSwitchResponse
   */
  export interface AsProtobufJSON {
    success: boolean;
    redirectUri: string | null;
  }
  export enum ResponseCase {
    none = 0,
    success = 1,
    redirectUri = 2,
  }
}
