import { AccessRule } from '../abstract-rule-access';
import { OperationCode } from '@camino-solutions/share/proto/common/services';

export class SubscriptionsListOwnRule extends AccessRule {
  static #instance: SubscriptionsListOwnRule;

  static get instance(): SubscriptionsListOwnRule {
    if (SubscriptionsListOwnRule.#instance === undefined) {
      SubscriptionsListOwnRule.#instance = new SubscriptionsListOwnRule();
    }
    return SubscriptionsListOwnRule.#instance;
  }

  constructor() {
    super(OperationCode.SubscriptionsListOwn);
    SubscriptionsListOwnRule.#instance = this;
  }
}
