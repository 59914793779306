import { PlatformOperationCode } from '@camino-solutions/share/proto/projects/platform';
import { AccessRule } from '../abstract-rule-access';

export class PlatformProjectsCreateProjectRule extends AccessRule {
  static #instance: PlatformProjectsCreateProjectRule;

  static get instance(): PlatformProjectsCreateProjectRule {
    if (PlatformProjectsCreateProjectRule.#instance === undefined) {
      PlatformProjectsCreateProjectRule.#instance = new PlatformProjectsCreateProjectRule();
    }
    return PlatformProjectsCreateProjectRule.#instance;
  }

  constructor() {
    super(PlatformOperationCode.ProjectsCreateProject);
    PlatformProjectsCreateProjectRule.#instance = this;
  }
}
