import { AccessRule } from '../abstract-rule-access';
import { OperationCode } from '@camino-solutions/share/proto/common/services';

export class SubscriptionsSetOrderPayedRule extends AccessRule {
  static #instance: SubscriptionsSetOrderPayedRule;

  static get instance(): SubscriptionsSetOrderPayedRule {
    if (SubscriptionsSetOrderPayedRule.#instance === undefined) {
      SubscriptionsSetOrderPayedRule.#instance = new SubscriptionsSetOrderPayedRule();
    }
    return SubscriptionsSetOrderPayedRule.#instance;
  }

  constructor() {
    super(OperationCode.SubscriptionsSetOrderPayed);
    SubscriptionsSetOrderPayedRule.#instance = this;
  }
}
