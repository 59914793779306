/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { GrpcMessage, RecursivePartial, ToProtobufJSONOptions } from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
export enum EnvironmentType {
  ENV_None = 0,
  ENV_Local = 1,
  ENV_LocalClient = 2,
  ENV_Development = 3,
  ENV_Test = 4,
  ENV_Staging = 5,
  ENV_Production = 6,
}
/**
 * Message implementation for registration.EnvironmentVisibility
 */
export class EnvironmentVisibility implements GrpcMessage {
  static id = 'registration.EnvironmentVisibility';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EnvironmentVisibility();
    EnvironmentVisibility.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EnvironmentVisibility) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: EnvironmentVisibility, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.public = _reader.readBool();
          break;
        case 2:
          _instance.inviteOnly = _reader.readBool();
          break;
        case 3:
          _instance.closedAudience = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    EnvironmentVisibility.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: EnvironmentVisibility, _writer: BinaryWriter) {
    if (_instance.public || _instance.public === false) {
      _writer.writeBool(1, _instance.public);
    }
    if (_instance.inviteOnly || _instance.inviteOnly === false) {
      _writer.writeBool(2, _instance.inviteOnly);
    }
    if (_instance.closedAudience || _instance.closedAudience === false) {
      _writer.writeBool(3, _instance.closedAudience);
    }
  }

  private _public: boolean;
  private _inviteOnly: boolean;
  private _closedAudience: boolean;

  private _visibility: EnvironmentVisibility.VisibilityCase =
    EnvironmentVisibility.VisibilityCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EnvironmentVisibility to deeply clone from
   */
  constructor(_value?: RecursivePartial<EnvironmentVisibility.AsObject>) {
    _value = _value || {};
    this.public = _value.public;
    this.inviteOnly = _value.inviteOnly;
    this.closedAudience = _value.closedAudience;
    EnvironmentVisibility.refineValues(this);
  }
  get public(): boolean {
    return this._public;
  }
  set public(value: boolean) {
    if (value !== undefined && value !== null) {
      this._inviteOnly = this._closedAudience = undefined;
      this._visibility = EnvironmentVisibility.VisibilityCase.public;
    }
    this._public = value;
  }
  get inviteOnly(): boolean {
    return this._inviteOnly;
  }
  set inviteOnly(value: boolean) {
    if (value !== undefined && value !== null) {
      this._public = this._closedAudience = undefined;
      this._visibility = EnvironmentVisibility.VisibilityCase.inviteOnly;
    }
    this._inviteOnly = value;
  }
  get closedAudience(): boolean {
    return this._closedAudience;
  }
  set closedAudience(value: boolean) {
    if (value !== undefined && value !== null) {
      this._public = this._inviteOnly = undefined;
      this._visibility = EnvironmentVisibility.VisibilityCase.closedAudience;
    }
    this._closedAudience = value;
  }
  get visibility() {
    return this._visibility;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EnvironmentVisibility.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EnvironmentVisibility.AsObject {
    return {
      public: this.public,
      inviteOnly: this.inviteOnly,
      closedAudience: this.closedAudience,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions,
  ): EnvironmentVisibility.AsProtobufJSON {
    return {
      public: this.public,
      inviteOnly: this.inviteOnly,
      closedAudience: this.closedAudience,
    };
  }
}
export module EnvironmentVisibility {
  /**
   * Standard JavaScript object representation for EnvironmentVisibility
   */
  export interface AsObject {
    public: boolean;
    inviteOnly: boolean;
    closedAudience: boolean;
  }

  /**
   * Protobuf JSON representation for EnvironmentVisibility
   */
  export interface AsProtobufJSON {
    public: boolean;
    inviteOnly: boolean;
    closedAudience: boolean;
  }
  export enum VisibilityCase {
    none = 0,
    public = 1,
    inviteOnly = 2,
    closedAudience = 3,
  }
}
